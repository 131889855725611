@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-dialog {
  min-width: 256px;
  background: var(--color-flat-panel-modal-theme);

  @media ($wx-sm-min) {
    max-width: 540px;
  }

  @media ($wx-md-min) {
    max-width: 720px;
  }
}

.close-btn {
  position: absolute;
  top: var(--dialog-close-offset);
  right: var(--dialog-close-offset);
}

.content {
  position: relative;
  width: auto;
  max-width: 100%;
  padding: var(--dialog-padding);
}

.dialog-title {
  margin-bottom: 16px;
}

.actions {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.notificationCounter {
  margin: 0 12px;
  font-weight: bold;
  font-size: 1.25rem;
}
