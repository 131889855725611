@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
/**
 * Wx Utility Classes + `.v-` quick fixes
 *
 * This file contains some minor modifications
 * to some Vuetify components. Future optimisation
 * would be to import Vuetify's Sass within our own
 * main.scss so we could customise $vars before the
 * get compiled.
 */

// Links
a:not([class]) {
  text-decoration: none;
}

// Anything disabled should not be clickabke
.disabled,
*[disabled] {
  pointer-events: none;
  cursor: auto;
}

/** - - - - - - - - - - - - -
 * Over-rule Vuetify styles
 */
.v-application {
  // multilingual quotes characters
  q {
    &:before {
      content: "\201c";
    }
    &:after {
      content: "\201d";
    }
    &:lang(es),
    &:lang(fr) {
      &:before {
        content: "\00ab\0020";
      }
      &:after {
        content: "\0020\00bb";
      }
    }
  }
  // reset html lists
  ul,
  ol {
    padding: 0;
    list-style: none;
  }

  // Base layers from wx-styleguide.figma
  .bgcolor-base-layer0 {
    background-color: var(--color-base-background);
  }
  .bgcolor-base-layer1 {
    background-color: var(--color-base-layer1);
  }
  .bgcolor-base-layer2 {
    background-color: var(--color-base-layer2);
  }

  // Element layers from wx-styleguide.figma
  .bgcolor-element-layer1 {
    background-color: var(--color-element-layer1);
  }
  .bgcolor-element-layer2 {
    background-color: var(--color-element-layer2);
  }
  .bgcolor-element-layer3 {
    background-color: var(--color-element-layer3);
  }

  // Preseve complete words when breaking lines
  .unbreak-word {
    word-break: break-word !important;
  }

  // Wx Themed Text Colors
  .color-text-theme {
    color: var(--color-text-theme) !important;
  }
  .color-text-disabled-theme {
    color: var(--color-text-disabled-theme) !important;
  }
  .color-text-subtle-theme {
    color: var(--color-text-subtle-theme) !important;
  }

  // Force cursor to display has clickable
  .cursor-pointer:hover {
    cursor: pointer;
  }

  // Generate inline-margins between childs of `d-flex`
  .flex-column-gap {
    column-gap: var(--grid-gutter);
  }
  .flex-small-column-gap {
    column-gap: calc(var(--grid-gutter) / 2);
  }
  .flex-button-column-gap {
    column-gap: 8px;

    > .v-btn.flex-grow-1 {
      margin-left: 0;
      margin-right: 0;
    }
  }

  // Container show vertical scrollbar when child overflows
  .scrollbar-y-container {
    overflow-x: hidden; // prevent horizontal scroll
    overflow-y: scroll;
    max-height: 100%;

    // cross-browser style hack: No Gecko/Firefox compatibility
    &::-webkit-scrollbar {
      width: var(--scrollbar-y-width);
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-control-theme);
      border-radius: var(--scrollbar-y-width);
      &:hover {
        background-color: var(--color-control-contrast-theme);
      }
    }
  }

  // Hack Vuetify Date Picker to prevent white on white
  .v-picker--date.v-card,
  .v-date-picker-header {
    // lightMode only
    &.theme--light {
      button {
        &[type="button"]:not(:hover):not(:focus),
        &.v-btn.v-btn--text {
          color: var(--color-blackText);
        }
      }
    }
  }

  // Over-rule components colors
  .v-btn-toggle:not(.v-btn-toggle--group) {
    background-color: transparent;
  }
  .v-divider {
    border-color: var(--color-border-theme);
  }
  .v-icon {
    color: var(--color-text-theme);
  }
  .v-list {
    background-color: var(--color-flat-panel-theme);

    // Small chevron button that triggers a dropwdown list
    &.chevron-menu-list {
      .v-list-item__title {
        min-width: 10rem;
        padding-left: 0.5rem;
      }
    }
  }
  // Wx default tabs
  .v-tabs .v-tabs-bar,
  .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tabs {
    .v-tabs-bar {
      .v-tab {
        text-transform: none;
        border-bottom: 1px solid var(--color-text-theme);

        &:not(.v-tab--active):not(.btn-toggle-tabs--item) {
          color: var(--color-text-theme);
        }
      }
    }
  }
  .v-textarea {
    &.v-text-field {
      textarea {
        line-height: 1.33; // match <wx-text-filed single-line>
      }
    }
  }
  .v-radio {
    &:hover,
    &:focus,
    &.v-item--active {
      label {
        color: var(--color-text-theme);
      }
    }
    // Unchecked radios
    &:not(.v-item--active) {
      .v-icon {
        color: var(--color-control-contrast-theme);
      }
      &:hover {
        .v-icon {
          color: var(--color-text-subtle-theme);
        }
      }
    }
  }

  // Wx hard coded width via class. (`.w-*` match Vuetify3 Sizing classes)
  .width100,
  .w-100 {
    width: 100% !important;
  }
  .width50,
  .w-50 {
    width: 50%;
  }
}

/** - - - - - - - - - - - - -
 * All alerts types
 */
.v-alert {
  &__content {
    color: var(--color-text-subtle-theme);
    h2,
    h3,
    h4,
    code,
    pre,
    a:not(.v-icon) {
      color: var(--color-text-theme);
    }
    a:not(.v-icon) {
      text-decoration: underline;
    }
  }

  &.no-data-alert {
    font-size: var(--font-size-sm);
    .v-icon {
      color: var(--color-control-theme);
    }
  }
  // Originally a vuetify help class for background-color
  &.transparent {
    padding: 0;
  }
}

/** - - - - - - - - - - - - -
 * Outlined Buttons
 * Only the border-color differs. Used on Dashboard’s PUItem button
 */
.v-btn--outlined {
  &:not([disabled]):not(:hover) {
    border-color: var(--color-control-stroke);
  }
  &[disabled] {
    border-color: var(--color-control-subtle-theme); // less contrasted
  }
}

/** - - - - - - - - - - - - -
 * Buttons with `beta` tag
 */
// model to extend
.extend_betaTag_string {
  display: inline-block;
  font-weight: 400; /* Regular */
  line-height: 1.1;
  text-transform: uppercase;
  border-radius: 3px;
}
.extend_betaTag_base {
  @extend .extend_betaTag_string;
  content: "beta";
}
// in context
.beta-tag {
  // <suv.beta-tag>
  @at-root sup#{&} {
    @extend .extend_betaTag_string;
    top: -0.6em;
    margin-right: 0.15rem;
    padding: 0.15em 0.5em;
    font-size: var(--font-size-sm);
    color: var(--color-inverted-container-theme);
    background-color: var(--color-neutral);
    &:after {
      content: "beta";
    }
  }
  @at-root .main-title sup#{&} {
    top: -0.9em;
    font-size: var(--font-size-norm);
  }
  // on any <span>
  @at-root span#{&}:not(.v-btn__content) {
    position: relative;
    &:after {
      @extend .extend_betaTag_base;
      position: absolute;
      top: -0.35em;
      margin-left: 0.5em;
      padding: 0.15em 0.5em;
      font-size: var(--font-size-sm);
      color: var(--color-inverted-container-theme);
      background-color: var(--color-neutral);
    }
  }
  // in an h1
  @at-root span#{&}.main-title__text {
    &:after {
      font-size: var(--font-size-norm);
    }
  }
  // in a button
  &.v-btn {
    .v-btn__content {
      position: relative;
      &:after {
        @extend .extend_betaTag_base;
        right: -1.4em;
        padding-left: 0.25rem;
        padding-bottom: 0.5em;
        font-size: var(--font-size-xs);
      }
    }
    &.v-size--large {
      .v-btn__content {
        &:after {
          top: -0.55em;
          right: -1.4em;
          font-size: var(--font-size-sm);
        }
      }
    }
    // … within a grouped button
    .v-btn-toggle > & {
      .v-btn__content {
        &:after {
          top: -0.85em;
        }
      }
    }
  }
}

/** - - - - - - - - - - - - -
 * Icons within large btn
 */
.v-btn--icon.v-size--large {
  .v-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
}

/** - - - - - - - - - - - - -
 * Grouped buttons container
 * Used in Dashboard’s Product and Justification panels header.
 *
 * DEV_NOTE: Prop `borderless` hides all borders.
 */
.v-btn-toggle {
  &:not(.v-btn-toggle--dense) {
    .v-btn.v-btn {
      &.v-size--small {
        height: 26px;
      }
      &.v-size--default {
        height: 30px;
      }
      &.v-size--large {
        height: 36px;
      }
    }
  }
}

/** - - - - - - - - - - - - -
 * Data Inline Grid
 * `dt.label` VS `dd.data` used in PUDetailsTime.vue
 */
dl.data-inline-grid {
  // var
  $dataInlineGutter: 0.25rem;
  // style
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  dt,
  dd {
    flex-shrink: 0;
    display: inline-block;
    width: calc(50% - #{$dataInlineGutter});
    margin: 0;
    padding: 0;
  }
  dt {
    align-self: flex-start;
  }
  dd {
    margin-left: ($dataInlineGutter * 2);
  }
  // If dt.label is very short
  &.narrow-dt {
    dt {
      width: calc(40% - #{$dataInlineGutter});
    }
    dd {
      width: calc(60% - #{$dataInlineGutter});
    }
    @media ($wx-md-min) {
      dt {
        width: calc(30% - #{$dataInlineGutter});
      }
      dd {
        width: calc(70% - #{$dataInlineGutter});
      }
    }
  }
  // If dt.label is longer then dd.data
  &.wide-dt {
    dt {
      width: calc(60% - #{$dataInlineGutter});
    }
    dd {
      width: calc(40% - #{$dataInlineGutter});
    }
  }
}

/** - - - - - - - - - - - - -
 * Data Reports
 * HTML Definition List <dl class="data-report">
 */
.data-report {
  .label {
    color: var(--color-text-subtle-theme);
  }
  .data {
    color: var(--color-text-theme);
    p {
      margin-bottom: 0;
      font-size: var(--font-size-h2);
      font-weight: 500;
    }
  }
}

/** - - - - - - - - - - - - -
 * Switch inputs
 * Over-rule font and color contrast according to tPlus Giveaways Dashboard
 */
.v-input {
  &.v-input--switch {
    font-size: var(--font-size-norm) !important;
    font-weight: 400; // Regular

    &:hover,
    &:focus,
    &.v-input--is-label-active {
      .v-label {
        color: var(--color-text-theme); // More contrast
      }
    }
  }
}

/** - - - - - - - - - - - - -
 * Menu Dropdowns
 * `v-select` and `wx-select` used in 
 * `Dashboard` and `Admin > wx-data-table footer`
*/
.v-menu__content.menuable__content__active {
  box-shadow: var(--box-shadow-high-elevation);
  border-radius: var(--border-radius-form-elements);
}
.v-menu__content .v-list.v-select-list {
  background-color: var(--color-element-layer3);
}

/** - - - - - - - - - - - - -
 * List Items Displayed Inline
 * Supported options on <li> based on Vuetify flex and typography classes
 *   * `text-right` Typography class  = Flex class `justify-end`
 *   * `text-center` Typography class = Flex class `justify-center`
 *   * Choose the punctuation to end the <li>
 *      * `comma-after`,
 *      * `end-point`.
 */
.wx-list-inline-items {
  display: flex;
  flex-wrap: wrap;

  // Supported options
  &.text-right {
    justify-content: flex-end;

    // fix vertical alignment (tPlus WorkShiftWizard)
    @media ($wx-sm-min) {
      &::after {
        padding-right: 0;
      }
    }
  }
  &.text-center {
    justify-content: center;
  }
  &.comma-after {
    > li {
      &::after {
        content: "\002c"; // comma
        padding-left: 0.1rem;
        padding-right: 0.3rem;
        color: var(--color-text-subtle-theme);
      }
      &:last-child {
        &::after {
          content: "";
          padding-right: 0;
        }
      }
    }
  }
  &.end-point {
    > li {
      &:last-child {
        &::after {
          content: ".";
          padding-right: 0;
        }
      }
    }
  }
}

// Used for factory-selector-list within <h1> (automn 2022, Tilelytics DPM)
.wx-page-title {
  font-size: var(--font-size-h2);
  line-height: 1.16666666;
  font-weight: 700;
}

/** - - - - - - - - - - - - -
 * Worximity Panels
 * Generic containers floating as maconnery grid
 * in responsive layout.
 */
.extend_panel_base {
  border-radius: var(--border-radius-sm);
  border-top: none;
}
.extend_panel_transparent {
  border: 1px solid var(--color-border-theme);
  background-color: transparent;
  box-shadow: none;
}

.wx-panel {
  &,
  &.v-sheet,
  &.v-sheet.v-card {
    padding: var(--wx-panel-container-padding);
    @extend .extend_panel_base;
    background-color: var(--color-base-background);
    box-shadow: none;
  }
  // .wx-panel__transparent
  &__transparent {
    &,
    &.v-sheet,
    &.v-sheet.v-card {
      @extend .extend_panel_base;
      @extend .extend_panel_transparent;

      // if .wx-panel__transparent within a .wx-panel
      @at-root .wx-panel #{&} {
        padding: calc(var(--wx-panel-container-padding) / 2);
      }
    }
  }
  // .wx-panel__transparent
  &__transparent {
    &,
    &.v-sheet,
    &.v-sheet.v-card {
      @extend .extend_panel_base;
      @extend .extend_panel_transparent;

      // if .wx-panel__transparent within a .wx-panel
      @at-root .wx-panel #{&} {
        padding: calc(var(--wx-panel-container-padding) / 2);
      }
    }
  }
}
.wx-panel-margin-bottom {
  margin-bottom: var(--grid-gutter) !important;
}
.wx-panel-container-padding {
  padding: var(--wx-panel-container-padding) !important;
}
.wx-panel-border-radius {
  border-radius: var(--border-radius-sm) !important;
}
// TilePlus Tiles
.wx-tile-panel {
  @extend .extend_panel_base;

  @media ($wx-sm-min) {
    padding: var(--box-padding-dashboard);
    border-radius: var(--border-radius-lg);
  }
}

// Fix height bug from v-row negative margins (automn 2022, Tilelytics DPM)
.wx-row-clearfix {
  > .row {
    margin-bottom: 0;
  }
}
