@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// local var
$maximized_h1_marginBottom: 2vh;

// styles
.wxapp-main-page {
  position: relative;

  ::v-deep > .v-main__wrap {
    padding: var(--grid-gutter);

    .main-title {
      margin-bottom: var(--grid-gutter);
      padding-top: 0.5em; // isMobile
    }

    // !isMobile
    @media ($wx-md-min) {
      padding-top: var(--v-application-top);
    }
    @media ($wx-lg-min) {
      .main-title {
        padding-top: 0.26em; // vertically align with drawer’s 1st icon
      }
    }

    // Complex headers (containing more then an `h1`) ------------↧↧↧TileLytics_ONLY
    /* .main-page-header-row {
      display: flex;
      align-items: stretch;
      margin-bottom: var(--grid-gutter);

      .main-title {
        max-width: calc(100% - 70px);
        margin-bottom: 0;
      }
      .page-header-tools {
        margin-left: auto;
      }

      // v-breadcrumbs above main-title
      &.header-breadcrumbs {
        flex-direction: column;

        .v-breadcrumbs {
          margin-bottom: 0.25rem;
        }
        .main-title {
          padding-top: 0;
        }

        // !isMobile
        @media ($wx-md-min) {
          margin-top: -0.3rem;
        }
        @media ($wx-lg-min) {
          margin-top: -0.8rem;
        }
        @media ($wx-xl-min) {
          margin-top: -0.6rem;

          .v-breadcrumbs {
            margin-bottom: 0;
          }
        }
      }
    } */
    // end -------------------------------------------------------↥↥↥TileLytics_ONLY
  }

  /** - - - - - - - - - - - - - - -
   * Page Height Maximized
   * initial design requirement: No scrollbar on TilePlus’s Dashboard when !isMobile.
   */
  &.main-page-height-maximized {
    ::v-deep > .v-main__wrap {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      // !isMobile
      @media ($wx-md-min) {
        padding-top: 1px; // vertically align with `wxapp-logo--drawer`

        .main-title {
          max-width: 850px; // fix layer conflict with wxapp-main-header
          margin-bottom: $maximized_h1_marginBottom;
        }

        // Complex headers --------------------------------------↧↧↧ TileLytics_ONLY
        /* .main-page-header-row {
          margin-bottom: $maximized_h1_marginBottom;

          .main-title {
            margin-bottom: 0;
          }
        } */
        // end --------------------------------------------------↥↥↥ TileLytics_ONLY
      }

      @media ($wx-lg-min) {
        padding-top: 4px; // vertically align with `wxapp-logo--drawer`
      }
      @media ($wx-xl-min) {
        padding-top: 1px; // vertically align with `wxapp-logo--drawer`
      }
    }
  }
}
