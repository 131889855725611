@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-application {
  &#wxapp {
    // gradient is set within layouts
    background-color: var(--color-base-theme);
  }

  // Status background-color is dynamic
  .wxapp-snackbar {
    &--container {
      position: fixed;
      z-index: 999;
      text-shadow: var(--white-text-shadow-smallest-level);
      top: 0;

      @media ($wx-isNotMobile) {
        top: 55px;
      }

      // Colors NOT subject to theme mode
      .close-btn {
        color: Black;
        background-color: White;
      }
    }

    /* 
    &__message {
      // if list-item
      &.messages {
        color: var(--color-inverted-text-theme);
      }
    }
    */
  }

  /** - - - - -
   * Presenter
   */
  &.presenter {
    padding: var(--grid-gutter);
    background-color: var(--color-pu-current-state);
    position: relative;
    transition: background-color 0.3s linear;
    overflow: hidden;

    &:after {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: var(--grid-gutter) solid var(--color-pu-current-state);
      z-index: 6;
      pointer-events: none;
      transition: border-color 0.3s linear;
    }

    ::v-deep .v-application--wrap {
      min-height: calc(100vh - var(--grid-gutter) * 2);
    }

    ::v-deep .v-navigation-drawer {
      max-height: calc(100% - var(--grid-gutter) * 2) !important;
      left: var(--grid-gutter) !important;
      top: var(--grid-gutter) !important;
    }
  }
}
