@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep line,
::v-deep polyline,
::v-deep rect {
  stroke: currentColor;
  fill: currentColor;
}

::v-deep polygon,
::v-deep path {
  fill: currentColor;
}

::v-deep g {
  fill: none;
}
