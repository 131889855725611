@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';
@import "_values";

/**
 * Wx Themed Colors
 *
 * Contrairy to colors from `_values.scss`
 * these colors are NOT exported to JavaScript.
 */
$wx-darkTheme-colorValues: (
  "base-theme": hsl(215, 48%, 12%),
  "base-background": hsl(215, 30%, 25%),
  "base-contrast-theme": hsl(215, 30%, 30%),
  "base-gradient-start": hsl(213, 55%, 20%),
  "base-gradient-end": hsl(215, 48%, 12%) /* ends darker */,
  "base-layer1": hsl(215, 48%, 12%),
  "base-layer2": hsl(215, 48%, 12%),
  "border-theme": hsla(215, 50%, 80%, 0.2),
  "border-contrast-theme": hsla(215, 20%, 80%, 0.8),
  "contrast-theme": White,
  "contrast-secondary": hsl(207, 30%, 85%),
  "control-background-theme": hsla(215, 30%, 70%, 0.15) /* used in ./variables.scss */,
  "control-contrast-theme": hsla(207, 30%, 75%, 0.3),
  "control-subtle-theme": hsla(215, 35%, 35%, 0.12),
  "control-stroke": hsl(215, 20%, 40%),
  "control-theme": hsla(207, 40%, 75%, 0.25),
  "element-layer1": hsl(215, 38%, 18%),
  "element-layer2": hsl(215, 23%, 34%),
  "element-layer3": hsl(215, 23%, 34%),
  "flat-panel-theme": hsl(215, 38%, 18%) /* --color-element-layer1 */,
  "flat-panel-modal-theme": hsl(215, 34%, 23%) /* --color-base-background */,
  "grid-background-subtle-theme": hsla(215, 60%, 90%, 0.075),
  "grid-background-theme": hsla(215, 60%, 90%, 0.15),
  "grid-ruler-theme": hsla(215, 60%, 90%, 0.15) /* charts data-grid */,
  "inverted-base-background": White,
  "inverted-border-theme": hsla(216, 27%, 63%, 0.3),
  "inverted-container-theme": hsl(240, 17%, 93%),
  "inverted-text-theme": hsl(225, 15%, 10%),
  "subtle-container-theme": hsla(0, 0%, 100%, 0.05),
  "text-disabled-theme": hsla(220, 20%, 50%, 0.6),
  "text-subtle-theme": hsla(0, 0%, 100%, 0.7),
  "text-theme": White,
  "tile-background": hsl(215, 30%, 25%) /* --color-base-background */,
  "timeline-graph-background": hsl(215, 25%, 29%),
);
// LIGHT
$wx-lightTheme-colorValues: (
  "base-theme": hsl(215, 40%, 97%),
  "base-background": White,
  "base-contrast-theme": hsl(215, 29%, 40%),
  "base-gradient-start": hsl(204, 45%, 97%) /* starts lighter */,
  "base-gradient-end": hsl(215, 40%, 94%),
  "base-layer1": hsl(215, 40%, 97%),
  "base-layer2": White,
  "border-contrast-theme": hsla(216, 20%, 50%, 0.4),
  "border-theme": hsla(216, 27%, 63%, 0.3),
  "contrast-theme": Black,
  "contrast-secondary": hsl(207, 30%, 30%),
  "control-background-theme": hsla(215, 30%, 30%, 0.1) /* used in ./variables.scss */,
  "control-contrast-theme": hsla(216, 29%, 35%, 0.15),
  "control-subtle-theme": hsla(216, 29%, 45%, 0.12),
  "control-theme": hsla(216, 29%, 35%, 0.25),
  "control-stroke": hsl(215, 18%, 82%),
  "element-layer1": White,
  "element-layer2": White,
  "element-layer3": White,
  "flat-panel-theme": White /* --color-element-layer1 */,
  "flat-panel-modal-theme": White,
  "grid-background-subtle-theme": hsla(215, 30%, 70%, 0.075),
  "grid-background-theme": hsla(215, 30%, 50%, 0.15),
  "grid-ruler-theme": hsla(215, 30%, 70%, 0.3) /* charts data-grid */,
  "inverted-base-background": hsl(215, 30%, 25%),
  "inverted-border-theme": hsla(215, 50%, 80%, 0.2),
  "inverted-container-theme": hsl(216, 29%, 30%),
  "inverted-text-theme": White,
  "subtle-container-theme": hsla(0, 0%, 0%, 0.05),
  "text-disabled-theme": hsla(220, 40%, 35%, 0.2),
  "text-subtle-theme": hsla(0, 0%, 0%, 0.6),
  "text-theme": hsla(220, 15%, 8%, 0.92),
  "tile-background": White /* --color-base-background */,
  "timeline-graph-background": hsl(0, 0%, 95%),
);

// Sass variables for shadow colors
$wxBoxShadow_hslValues: 216 91% 4%;
$wxTextShadow_hslValues: 216 83% 2%;

/* -------------------------------------
 * CSS VARs
 */
:root {
  // Wx Unthemed Colors
  @each $name, $value in $wx-unthemed-colorValues {
    --color-#{$name}: #{$value};
  }
  @each $name, $value in $wxSmartFactory-colorValues {
    --color-#{$name}: #{$value};
  }
  @each $name, $value in $wxProductionState-colorValues {
    --color-#{$name}: #{$value};
  }

  // Wx Spacing
  --grid-gutter: #{$wx-grid-gutter-xs};
  --box-padding-admin: 12px;
  --box-padding-dashboard: 12px;
  --dialog-padding: 12px;
  --dialog-small-padding: 6px;
  --dialog-close-offset: 12px;
  --field-vertical-gutter: 6px;
  --modal-window-narrow-width: 90vw;
  --modal-window-medium-width: 96vw;
  --scrollbar-y-width: 10px;
  --wx-panel-container-padding: 12px;
  @media ($wx-sm-min) {
    --grid-gutter: #{$wx-grid-gutter-sm};
    --box-padding-admin: 16px;
    --box-padding-dashboard: 16px;
    --dialog-padding: 20px;
    --modal-window-narrow-width: 60vw;
    --modal-window-medium-width: 80vw;
    --wx-panel-container-padding: 16px;
  }
  @media ($wx-md-min) {
    --grid-gutter: #{$wx-grid-gutter-md};
    --box-padding-admin: 20px;
    --dialog-padding: 24px;
    --dialog-close-offset: 16px;
    --field-vertical-gutter: 9px;
    --modal-window-narrow-width: 500px;
    --modal-window-medium-width: 60vw;
    --wx-panel-container-padding: 20px;
  }
  @media ($wx-lg-min) {
    --grid-gutter: #{$wx-grid-gutter-lg};
    --box-padding-dashboard: 20px;
    --dialog-padding: 28px;
    --dialog-close-offset: 20px;
    --modal-window-medium-width: 800px;
  }
  @media ($wx-xl-min) {
    --grid-gutter: #{$wx-grid-gutter-xl};
    --box-padding-admin: 24px;
    --box-padding-dashboard: 24px;
    --dialog-padding: 32px;
    --field-vertical-gutter: 12px;
    --wx-panel-container-padding: 24px;
  }

  // Wx Typography
  --font-size-h1: 1.5rem;
  --font-size-h2: 1.125rem;
  --font-size-h3: 0.875rem;
  --font-size-norm: #{$wx-body-font-size-xs};
  --font-size-sm: 0.75rem;
  --font-size-xs: 0.625rem;
  @media ($wx-sm-min) {
    --font-size-h1: 1.75rem;
    --font-size-h2: 1.25rem;
    --font-size-h3: 1rem;
    --font-size-norm: #{$wx-body-font-size-sm};
  }
  @media ($wx-md-min) {
    --font-size-h1: 1.875rem;
  }
  @media ($wx-lg-min) {
    --font-size-h1: 2.25rem;
    --font-size-h2: 1.5rem;
    --font-size-h3: 1.125rem;
    --font-size-sm: 0.875rem;
    --font-size-xs: 0.75rem;
  }
  @media ($wx-xl-min) {
    --font-size-h1: 3rem;
    --font-size-h2: 1.75rem;
    --font-size-h3: 1.375rem;
    --font-size-norm: #{$wx-body-font-size-xl};
    --font-size-sm: 1rem;
    --font-size-xs: 0.875rem;
  }

  // Buttons vertical sizes
  --btn-height-sm: 26px;
  --btn-height-default: 30px;
  --btn-inline-margin: 0.9rem; // ~14px

  // Border-radius dimensions
  --border-radius-form-elements: 5px;
  --border-radius-sm: 5px;
  --border-radius-lg: 10px;
  @media ($wx-sm-min) {
    --border-radius-sm: 8px;
    --border-radius-lg: 12px;
  }
  @media ($wx-md-min) {
    --border-radius-lg: 16px;
  }
  @media ($wx-xl-min) {
    --border-radius-sm: 12px;
  }

  // Opacity Values
  --opacity-disabled-effect: 0.5;
  --opacity-before-effect-low: 0.08;
  --opacity-before-effect-high: 0.3;

  // Transitions Animations
  --smooth-transition: all 0.3s ease;
  --big-transition: all 0.5s ease; // big elements requires more time to move to prevent dizziness

  /**
   * `currentProductionUnitState`
   * In `./App.vue`, line#79, switch cases to
   * apply color on the «Current state of the
   * production unit».
   * Default is "out-of-production".
   */
  --color-pu-current-state: var(--color-out-of-production);

  /**
   * Layer Elevation Class
   * Because Vuetify Elevation helper
   * class can NOT @extend within our
   * our custom components scoped scss
   */
  --box-shadow-small-elevation: 0 3px 1px -2px hsla(#{$wxBoxShadow_hslValues} / 16%),
    0 2px 2px 0 hsla(#{$wxBoxShadow_hslValues} / 13%), 0 1px 5px 0 hsla(#{$wxBoxShadow_hslValues} / 10%);
  --box-shadow-high-elevation: 0 3px 5px -1px hsla(#{$wxBoxShadow_hslValues} / 20%),
    0 5px 8px 0 hsla(#{$wxBoxShadow_hslValues} / 14%), 0 1px 14px 0 hsla(#{$wxBoxShadow_hslValues} / 12%);

  /**
  * White text contrast fix
  * IMPORTANT: these vars are NOT subject to theme Light/Dark modes
  */
  --white-text-shadow-smallest-level: 0 1px 2px hsla(#{$wxTextShadow_hslValues} / 25%);
  --white-text-shadow-small-level: 0 1px 2px hsla(#{$wxTextShadow_hslValues} / 35%);
  --white-text-shadow-high-level: 0 2px 3px hsla(#{$wxTextShadow_hslValues} / 14%);

  /**
   * Vuetify Application Services Props
   * (https://vuetifyjs.com/en/components/application/#application-service)
   * `$vuetify.application[ bar,bottom,footer,insetFooter,left,right,top ]`
   * In wxapp all props values are `0` except for prop `top`
   *
   * --v-application-top = $vuetify.application.top
   */
  --v-application-top: 56px; // isMobile
  @media ($wx-md-min) {
    --v-application-top: 64px; // !isMobile
  }
}

/** ------------------------------------------------
 * Wx CSS Custom Properties
 */
.v-application {
  // Typography utility class
  .wx-subtle-text {
    color: var(--color-text-subtle-theme);
  }

  /**
   * Linear Gradients
   * Fill empty containers with oblique bars as background-image
   *
   * Replaces the obsolete sets of variables
   *   * var(--pattern-upcoming-time-theme) 
   *   * var(--pattern-upcoming-time-sm-theme)
   */
  --repeating-oblique-pattern: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 10px,
    var(--color-control-background-theme) 10px,
    var(--color-control-background-theme) 20px
  );
  --repeating-oblique-small-pattern: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 5px,
    var(--color-control-background-theme) 5px,
    var(--color-control-background-theme) 10px
  );

  /* ------------------------------------------------⬇⬇Light⬇⬇
   *
   * DARK MODE
   *
   */
  &.theme--dark {
    // DARK: Color Loops
    @each $key, $value in $material-darkTheme-vuetifyPropOptions {
      --color-#{$key}: #{$value};
      // HSL to 20% RGB Alpha
      $alphaValue: rgba($value, 0.2);
      --color-#{$key}-alpha: #{$alphaValue};
    }
    @each $key, $value in $wxDatas-darkTheme-colorValues {
      --#{$key}: #{$value};
    }
    @each $key, $value in $wx-darkTheme-colorValues {
      --color-#{$key}: #{$value};
    }

    // DARK: Gradient for brackground-image (OBSOLETE)
    --theme-linear-gradient: linear-gradient(
      142deg,
      var(--color-base-gradient-start) 2%,
      var(--color-base-gradient-end) 100%
    );

    // DARK: Text shadows use to fix WCAG contrast issues
    --text-shadow-smallest-level: 0 1px 3px hsla(0, 0%, 0%, 0.35);
    --text-shadow-small-level: 0 1px 3px hsla(0, 0%, 0%, 0.25);
    --text-shadow-high-level: 0 3px 4px hsla(0, 0%, 0%, 0.35);
  }

  /* ------------------------------------------------⬆⬆Dark⬆⬆
   *
   * LIGHT MODE
   *
   */
  &.theme--light {
    // LIGHT: Color Loops
    @each $key, $value in $material-lightTheme-vuetifyPropOptions {
      --color-#{$key}: #{$value};
      // HSL to 20% RGB Alpha
      $alphaValue: rgba($value, 0.2);
      --color-#{$key}-alpha: #{$alphaValue};
    }
    @each $key, $value in $wxDatas-lightTheme-colorValues {
      --#{$key}: #{$value};
    }
    @each $key, $value in $wx-lightTheme-colorValues {
      --color-#{$key}: #{$value};
    }

    // LIGHT: Containers gradient brackground (OBSOLETE)
    --theme-linear-gradient: linear-gradient(
      116deg,
      var(--color-base-gradient-start) 2%,
      var(--color-base-gradient-end) 92%
    );

    // Light: Text shadows use to fix WCAG contrast issues
    --text-shadow-smallest-level: 0 1px 3px rgba(255, 255, 255, 0.75);
    --text-shadow-small-level: 0 1px 3px rgba(255, 255, 255, 0.75);
    --text-shadow-high-level: 0 3px 4px rgba(255, 255, 255, 0.65);
  }
}
