@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-btn {
  &.neutral {
    background: none !important;
    transition-property: box-shadow, transform, opacity, color;

    &.v-btn--disabled {
      background-color: transparent !important;
    }

    &::before {
      background-color: var(--color-contrast-theme);
      opacity: var(--opacity-before-effect-low);
    }

    &:hover,
    &:focus {
      &.neutral::before {
        opacity: var(--opacity-before-effect-high);
      }
    }
  }
  &.neutral--text,
  &.secondary--text {
    color: var(--color-text-theme) !important;
    caret-color: var(--color-text-theme) !important;
  }

  &.v-size--default,
  &.v-size--small {
    padding: 0 14px;
  }

  .v-icon {
    &.v-icon--right {
      margin-left: 6px;
      margin-right: -2px;
    }
    &.v-icon--left {
      margin-left: -2px;
      margin-right: 6px;
    }
  }

  &.v-size--small {
    .v-icon {
      &.v-icon--right {
        margin-left: 4px;
        margin-right: -6px;
      }
      &.v-icon--left {
        margin-left: -6px;
        margin-right: 4px;
      }
    }
  }
}
