@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.wxapp-main-page {
  ::v-deep > .v-main__wrap {
    padding: 0 var(--grid-gutter);

    @media ($wx-isMobile) {
      padding-bottom: $wx_vuetify_application_top; // for vertical alignment in viewport
    }

    // LoginPanel
    .wx-card-container {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      width: 100%;
    }
  }

  /** - - - - - - - - - - - - - - -
  * Page centered contents
  * without MainNavigation
  */
  &.centered-main-page {
    ::v-deep > .v-main__wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .wx-card-container {
        margin-inline: auto;

        @media ($wx-isMobile) {
          max-width: 300px;
        }
        @media ($wx-isNotMobile) {
          max-width: 400px;
        }
      }
    }
  }
}
