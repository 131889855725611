@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.activator:before {
  @media ($wx-md-min) {
    opacity: var(--opacity-before-effect-low);
  }
}

.v-menu__content {
  box-shadow: var(--box-shadow-high-elevation);
}

.container {
  padding-top: 20px;
  width: 240px;
  background-color: var(--color-flat-panel-theme);
}

.whats-new-title {
  font-size: 1rem;
  font-weight: 700;
}

.v-list-item {
  min-height: 40px;
}
