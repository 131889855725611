@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-autocomplete__content {
  &.v-menu__content {
    background-color: var(--color-element-layer2);
  }

  .v-list {
    &.v-select-list {
      background-color: transparent;
    }
  }
}

.v-autocomplete {
  /**
  * Filter styled field
  * Wx Design request no border-bottom
  */
  &.filter-style {
    .v-input__control {
      .v-input__slot {
        border-radius: var(--border-radius-form-elements);

        // hide border-bottom
        &::before,
        &::after {
          display: none;
          border-color: transparent !important;
        }
      }
    }

    :hover {
      cursor: pointer;
    }
  }
}
